/* .lg-zoom-in, */
a#lg-download-3 {
  display: none;
}
#lg-actual-size-3 {
  display: none;
}

.gallery-page {
  font-family: 'Poppins', sans-serif;
}

.gallery-banner {
  position: relative;
}

.gallery-banner img {
  width: 100%;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: bold;
  color: white;
  letter-spacing: 2px;
  font-family: 'Poppins', sans-serif;
}

.gallery-header {
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.gallery-header h2 {
  display: inline-block;
  color: #000;
  margin: 0;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 30.8px;
  letter-spacing: 2.5px;
  text-align: center;
}

.gallery-header h2::after {
  content: '';
  display: block;
  width: 50%;
  margin: 8px auto;
  height: 2px;
  background-color: #ff6b34;
}

.gallery-grid .lg-react-element {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding: 0 20px;
}

.gallery-grid .lg-react-element a img {
  width: 100%;
  height: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.pagination button {
  margin: 0 5px;
  padding: 10px 20px;
  background-color: white;
  color: #f15a24;
  border: 2px solid #f15a24;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button.active {
  background-color: #f15a24;
  color: white;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  border: 2px solid #a0a0a0;
  cursor: not-allowed;
}

.award-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.award-grid {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 400%;
}

.award-item {
  flex: 1;
  min-width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.award-item img {
  width: 100%;
  height: auto;
  max-width: 500px;
  max-height: 500px;
  object-fit: cover;
}

.awards {
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.awards h2 {
  display: inline-block;
  margin: 0;
  padding-bottom: 10px;
  position: relative;
}

.awards h2::after {
  content: '';
  display: block;
  width: 100px;
  height: 4px;
  background-color: #f15a24;
  margin: 0 auto;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.awards-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.awards-nav-button {
  background-color: #f15a24;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.awards-nav-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

/* animations */

.gallery-grid .lightgallery-item {
  position: relative;
}

.gallery-grid .lightgallery-item::after {
  content: '';
  position: absolute;
  content: '';
  display: block;
  top: 20px;
  left: 20px;
  bottom: 20px;
  right: 20px;
  border: 5px solid #fff;
  opacity: 0.26;
  transition: all 0.5s;
}

.gallery-grid .lightgallery-item:hover::after {
  inset: 0;
}

.gallery-grid .lightgallery-item::before {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  background-color: #f15a24;
  transition: all 0.5s;
}

.gallery-grid .lightgallery-item:hover::before {
  opacity: 0.75;
}

.lightgallery-item .search-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
  width: 50px;
  aspect-ratio: 1;
  border: 1px solid #fff;
  display: grid;
  place-content: center;
  opacity: 0;
  transition: all 0.5s;
  z-index: 2;
}

.gallery-grid .lightgallery-item:hover .search-icon {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .gallery-grid .lg-react-element {
    grid-template-columns: 1fr;
  }

  .banner-text {
    font-size: 1.5rem;
    left: 50%;
    z-index: 9;
  }

  .gallery-banner {
    height: 40vh;
  }

  .gallery-banner::after {
    content: '';
    inset: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .gallery-banner img {
    height: 100%;
    object-fit: cover;
    object-position: right;
  }
}
