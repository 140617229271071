/* src/HomePage.css */

h2 {
  margin: 0;
}

.homepage-container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  flex-wrap: wrap;
}

/* Header Styling */
.home-page-header {
  display: flex;
  overflow: hidden;
  background-color: white;
  flex-direction: row;
  height: auto;
}

.image-container {
  flex: 62%;
}

.truck-image {
  width: 100%;
  height: 100%;
}

.homepage-content {
  flex: 38%;
  padding: 5%;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}

.content-title {
  margin-bottom: 15px;
  font-size: 38px;
  font-weight: 700;
}

.content-motto {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
}

.content-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-right: 160px;
}

.contact-button {
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  color: #ff6b34;
  flex: 1;
  width: 128px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
}

.services-button {
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  color: white;
  flex: 1;
  background-color: #ff6600;
  width: 128px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 5px;
}

.contact-button:hover {
  background-color: #ff6600;
  color: #dedede;
}

.contact-button:hover {
  background-color: #e55d00;
  color: #dedede;
}

.services-button:hover {
  color: #e55d00;
  background-color: #dedede;
}

/* Quote Styling */

.quote-request {
  margin-top: 40px;
  text-align: center;
  background-image: url('./assets/quote-request-background.png');
  background-position: center;
  background-repeat: no-repeat;
  color: #ffffff;
  display: flex;
  justify-content: center;
  height: 720px;
}

.quote-request-form-container {
  opacity: 80%;
  background-color: #000000cc;
  width: 669px;
  padding-top: 70px;
}

.quote-request-subtitle {
  font-weight: 100;
  font-size: 13px;
}

.quote-request form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.quote-request input {
  margin: 10px;
  padding: 10px;
  width: calc(100% - 40px);
  max-width: 400px;
  background-color: #000000cc;
  color: #ffffff;
  border-style: solid;
  border: 1px solid #dedede80;
}

.quote-request input::placeholder {
  color: #ffffff;
}

.quote-request button {
  margin-top: 10px;
  padding: 10px;
  opacity: 1;
  width: calc(100% - 20px);
  max-width: 400px;
  color: #ffffff;
  font-weight: 600;
  background-color: #ff6600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.profile-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.profile-item,
.feature-item {
  flex: 1 1 calc(33% - 40px);
  margin: 20px;
  padding: 20px;
}

.profile-description {
  margin-top: 2rem;
  font-weight: 600;
}

@media (max-width: 768px) {
  .profile-item,
  .feature-item {
    flex: 1 1 100%;
  }
}

/* src/CompanyProfile.css */
.section-container {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 37px 64px;
  background-position: center;
  background-repeat: no-repeat;
}
.company-profile {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 37px 64px;
  background-image: url('./assets/world-map.png');
  background-position: center;
  background-repeat: no-repeat;
}

.profile-title {
  font-size: 28px;
  /* margin-bottom: 14px; */
  font-weight: 700;
  letter-spacing: 2.8px;
  line-height: 30.8px;
  opacity: 1;
}

.profile-subtitle {
  font-size: 16px;
  padding: 0px 200px;
  font-weight: 500;
  letter-spacing: 0.65px;
  opacity: 1;
  margin: 2rem 0;
}

.profile-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-container {
  flex: 4;
}

.profile-image {
  max-width: 100%;
  height: auto;
  margin-right: 20px;
  object-fit: cover;
  border: 4px solid #ffffff66;
}

.profile-items {
  flex: 5;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.profile-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
  gap: 20px;
  color: #727272;
}

/* .profile-icon-container {
  padding: 12px;
  background-color: #fff6f2;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
} */

.profile-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  border: 14px solid #fff6f2;
  background-color: #fff6f2;
  overflow: visible;
}

.profile-text {
  text-align: left;
}

.feature-title {
  font-size: 17px;
  margin: 0;
  color: #222222;
}

.profile-item-description {
  font-size: 17px;
  margin: 0;
}

.homepage-subtitle {
  color: #808080;
  font-weight: 100;
  font-size: 13px;
}

/* Why Choose Us */

.why-choose-us-container {
  margin-top: 40px;
  background-image: url('./assets/world-map.png');
  width: 100%;
}

.why-choose-us-header {
  text-align: center;
}

.features {
  display: flex;
  padding: 10px;
}

.features-right {
  flex: 1;
}

.features-left {
  flex: 1;
}

.features-left-text {
  text-align: right;
}

.transport-truck {
  width: 100%;
  height: auto;
  max-width: 30%;
  object-fit: contain;
}

/* Clinet-Info */

.clients-info {
  background-color: #f9f9f9;
}

.clinet-list {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding: 20px 60px;
}

.client-logo {
  border: 1px solid #dedede;
  padding: 20px;
  max-width: 270px;
  width: 270px;
}

/* Award-Info */

.awards-info {
  margin-bottom: 40px;
}

.award-list {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  padding: 20px 60px;
}

.certificate-img {
  padding: 6px;
  max-width: 270px;
  background-color: #fff6f2;
  width: 310px;
  max-width: 310px;
  max-height: 246px;
}
/* sdsdds */

.baseline-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically if needed */
}

.home-baseline {
  width: 150px;
  height: 2px;
  background-color: #ff6b34;
  margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  .home-page-header {
    position: relative;
    height: 40vh;
  }

  .image-container {
    position: relative;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .homepage-content {
    width: 100vw;
    height: 40vh;
    padding: 0;
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
  }

  .content-motto {
    font-weight: 600;
  }

  .content-title {
    margin-bottom: 0;
    padding: 30px 0;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
  }

  .content-buttons {
    margin-right: 0;
  }

  .contact-button {
    padding: 10px 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: none;
    cursor: pointer;
    color: #ff6600;
    flex: 1;
    width: fit-content;
    font-weight: 500;
    border: 1px solid #ff6600;
  }

  .services-button {
    padding: 10px 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    border: none;
    cursor: pointer;
    color: white;
    flex: 1;
    width: fit-content;
    font-weight: 500;
    border: 1px solid white;
  }

  .company-profile {
    padding: 42px 16px;
  }

  h2.profile-title {
    font-size: 24px;
    /* margin-bottom: 14px; */
    font-weight: 700;
    letter-spacing: 2.8px;
    line-height: 30.8px;
    opacity: 1;
  }

  .profile-subtitle {
    font-size: 16px;
    padding: 0px 20px;
    font-weight: 500;
    letter-spacing: 0.65px;
    opacity: 1;
  }

  .profile-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .profile-item {
    flex-direction: column;
  }

  .profile-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
    border: 14px solid #fff6f2;
    background-color: #fff6f2;
    overflow: visible;
  }

  .features {
    flex-direction: column;
  }

  .features-left-text,
  .profile-text {
    text-align: center;
  }

  .features-left {
    order: 0;
  }

  .features-right {
    order: 1;
  }

  .transport-truck {
    order: 2;
    max-width: 100%;
    margin-top: 2.5rem;
  }

  .profile-item {
    display: flex;
    flex-direction: column;
  }

  .profile-icon-container {
    order: 0;
  }

  .profile-text {
    order: 1;
  }

  .contact-button:hover {
    background-color: #ff6600;
    color: #dedede;
  }

  .services-button:hover {
    color: #e55d00;
    background-color: #dedede;
  }
}

@media screen and (min-width: 678px) and (max-width: 1024px) {
  .homepage-content {
    gap: 2rem;
  }

  .content-title {
    font-size: 42px;
  }

  .features {
    padding: 0 3rem;
  }

  .contact-button:hover {
    background-color: #ff6600;
    color: #dedede;
  }

  .services-button:hover {
    color: #e55d00;
    background-color: #dedede;
  }
}
