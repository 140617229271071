.map-container {
  position: relative;
  /* white-space: nowrap; */
}

.map-image {
  width: 100%;
  display: block;
}

.city-point {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ff6b34;
  border-radius: 50%;
  cursor: pointer;
}

.city-point:hover {
  outline: 1px solid #ff6b34;
  outline-offset: 2px;
}

.city-point.active {
  outline: 1px solid #ff6b34;
  outline-offset: 2px;
}

.city-point .tooltip {
  position: absolute;
  width: 350px;
  top: 20px;
  left: -10px;
  padding: 10px;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  display: none;
  padding: 10px;
  border: 1px solid #ff6b34;
  z-index: 9;
}

.city-point:hover .tooltip {
  display: block;
}

.city-point.active .tooltip {
  display: block;
}

.tooltip-title {
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2.8px;
}

.tooltip-address,
.tooltip-spoc,
.tooltip-contact,
.tooltip-email {
  margin-bottom: 3px;
  color: #717171;
  font-size: 14px;
  line-height: 1.2;
}
