@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #fff;
}

h3 {
  display: inline-block;
}

p,
h3 {
  padding: 0;
  margin: 0;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.gap-2 {
  gap: 1rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-center {
  text-align: center;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.footer {
  background-color: #212121;
  color: #fff;
  font-size: 14px;
}

.footer-container {
  display: grid;
  padding: 3rem;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  gap: 24px;
  margin-left: 5rem;
}

.footer-heading {
  padding-bottom: 14px;
}

.footer-heading::before {
  content: '';
  display: inline-block;
  width: 2px;
  height: 1.7em;
  background-color: #ff6b34;
  margin-right: 10px;
  vertical-align: middle;
}

.footer-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.social-icons {
  margin-left: 1rem;
  display: flex;
  gap: 1rem;

  font-size: 24px;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.hr-container {
  margin: 2rem 0;
  border-top: 1px solid #c7c7c7;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.footer-end {
  padding: 20px 0;
  color: #c7c7c7;
}

.footer-end > strong {
  color: #fff;
}

@media screen and (max-width: 1000px) {
  .footer-container {
    margin-left: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding: 3rem 2rem;
    gap: 42px;
  }

  .footer-section:nth-child(1),
  .footer-section:nth-child(2) {
    grid-column: span 2;
    justify-content: center;
  }

  .footer-section:nth-child(3),
  .footer-section:nth-child(4) {
    grid-column: span 1;
  }

  .footer-section:nth-child(3) {
    order: 4;
  }

  .footer-section:nth-child(4) {
    order: 3;
  }

  .hr-container {
    margin: 0;
    img {
      transform: translate(0%, 0%);
      top: -70px;
      left: 80%;
    }
  }
}
