h4 {
  margin: 0;
  padding: 0;
}

.services {
  margin: 0 auto;
}

.services__hero {
  position: relative;
  height: 40vh;
}

.services__hero-title {
  position: absolute;
  top: 24%;
  left: 15%;

  font-size: 2.5em;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: #fff;
}

.services__hero > img {
  height: 40vh;
  width: 100%;
  object-fit: cover;
}

.services__list {
  display: grid;
  place-items: center;
}

.services__item-wrapper {
  display: grid;
  grid-template-columns: 1fr 0.9fr;
  gap: 2rem;
  padding: 3rem;
  place-items: center;
}

.services__item-wrapper:nth-child(even) {
  grid-template-columns: 0.9fr 1fr;
  background-color: #f9f9f9;
}

.services__item-wrapper:nth-child(even) .service__image {
  order: 2;
}

.service__image {
  padding: 1rem;
}

.service__image img {
  max-width: 525px;
  max-height: 322px;
}

.service__details {
  padding: 1rem;
}

.service__title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.8px;
  position: relative;
  margin-bottom: 24px;
}

.service__title::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 10%;
  height: 2px;
  background-color: #ff6b34;
}

.service__list {
  margin: 0;
  padding: 16px;
  list-style-type: none;
  list-style-image: url('./assets/bullet_point.svg');
  list-style-position: 12px;
}

.service__list-item {
  padding-left: 8px;
  margin-bottom: 16px;
  color: #727272;
  font-size: 18px;
  letter-spacing: 1.2px;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .services__item-wrapper {
    grid-template-columns: 0.9fr 1fr;
    place-items: center;
  }

  .services__item-wrapper:nth-child(even) {
    grid-template-columns: 1fr 0.9fr;
  }

  .service__image,
  .service__details {
    padding: 1rem;
  }

  .service {
    grid-template-columns: 0.9fr 1fr;
  }

  .service__image img {
    max-width: 100%;
    max-height: 100%;
  }

  .service__title {
    font-size: 1.5rem;
  }

  .service__list-item {
    font-size: 1rem;
  }

  .service__list {
    padding: 0;
  }

  .service__list-item {
    font-size: 1rem;
  }

  .services__hero-title {
    top: 25%;
    left: 15%;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .services__hero-title {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    z-index: 2;
  }

  .services__hero::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .services__item-wrapper {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .services__item-wrapper:nth-child(even) {
    grid-template-columns: 1fr;
  }

  .services__item-wrapper:nth-child(even) .service__image {
    order: 2;
  }

  .service {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  }

  .service__image {
    padding: 1rem;
    order: 2;
  }

  .service__image img {
    max-width: 100%;
    max-height: 100%;
  }

  .service__details {
    padding: 1rem;
  }

  .service__title {
    font-size: 1.2rem;
  }

  .service__list {
    padding: 0;
  }

  .service__list-item {
    font-size: 1rem;
  }
}
