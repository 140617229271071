/* Mobile first view  */

.contact_us_container {
  width: 100%;
}

.mb-10 {
  margin-bottom: '10px';
}

.contact_us_container {
  width: 100%;
}

.contact_banner_container {
  position: relative;
  width: 100%;
  height: 40vh;
}

.contact_banner_container > img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.contact_banner_container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.contact_banner_left_content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
  height: 100%;
}

.banner_h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 38px;
  font-weight: 600;
  text-wrap: nowrap;
  z-index: 2;
  margin: 0;
  padding: 0;
}

.hq_data {
  padding: 2rem 1.5rem;
  background-color: #f9f9f9;
}

.hq_card_container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.hq_card_image {
  outline: 8px solid rgba(255, 255, 255, 0.4);
  outline-offset: -8px;
  width: 100%;
}

.hq_card_image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hq_card_data {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-block: 10px;
}

.hq_card_data > img {
  padding: 6px;
  border-radius: 50%;
  background-color: #fff;
}

.hq_card_heading_text {
  font-size: 28px;
  text-align: center;
}

.hq_card_heading_text::after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background-color: #ff6b34;
  margin: 10px auto;
}

.locations_sector {
  width: 100%;
}

.location_container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  margin-left: 2rem;
  gap: 28px;
}

.location_card_container {
  width: 300px;
}

.location {
  font-size: 18px;
  margin-bottom: 8px;
  color: #222222;
}

.location_detail {
  font-size: 14px;
  color: #717171;
}

.other_location {
  text-align: center;
  margin: 24px auto;
  font-size: 28px;
  width: fit-content;
  position: relative;
}

.other_location::after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background-color: #ff6b34;
  margin: 10px auto;
}

/* General styles */
.email_us {
  padding: 20px;
  margin: 0 auto;
}

.send_message {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* Mobile styles */
.email_us_content_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.email_us_image_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.email_us_image {
  width: 100%;
  height: auto;
}

.email_us_two_image {
  display: flex;
  align-items: start;
  gap: 30px;
}

.email_us_two_image > img {
  width: calc(50% - 15px);
  height: auto;
  object-fit: contain;
}

.email_us_form_container {
  display: flex;
  flex-direction: column;
}

.email_us_input {
  font-size: 16px;
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  outline: none;
  color: #727272;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
}

.email_us_input:focus {
  border-color: #ff6b34;
}

.submit_button {
  display: flex;
  width: 50%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  background-color: #ff6b34;
  padding: 12px 40px;
  border: none;
  cursor: pointer;
}

.submit_button img {
  margin-right: 5px;
}

#message {
  min-height: 120px;
  resize: vertical;
}

.contact_us-success-message {
}
.contact_us-error-message {
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contact_banner_container {
    height: 40vh;
  }

  .banner_h1 {
    font-size: 48px;
  }

  .hq_card_container {
    flex-direction: row;
    gap: 20px;
  }

  .hq_card_image {
    width: 350px;
  }

  .hq_card_image > img {
    width: 350px;
  }

  .hq_card_data {
    flex-direction: row;
    gap: 10px;
  }

  .hq_card_heading_text {
    font-size: 32px;
  }

  .location_container {
    grid-template-columns: repeat(2, 1fr);
  }

  .location_card_container {
    width: 100%;
  }

  .location {
    font-size: 20px;
  }

  .location_detail {
    font-size: 16px;
  }

  .submit_button {
    justify-content: center;
  }

  .email_us_content_container {
    flex-direction: row;
    gap: 40px;
  }

  .email_us_image_container {
    width: 50%;
  }

  .email_us_form_container {
    width: 50%;
  }

  .email_us_two_image {
    flex-direction: row;
    gap: 20px;
  }

  .submit_button {
    justify-content: center;
  }
}

/* Desktop styles */
@media (min-width: 1024px) {
  .contact_banner_container {
    height: 40vh;
  }

  .banner_h1 {
    font-size: 48px;
    visibility: hidden;
  }

  .hq_data {
    padding: 2rem 4rem;
  }

  .hq_card_container {
    flex-direction: row;
    gap: 4rem;
    margin-bottom: 3rem;
  }

  .hq_card_content {
    width: 50%;
    max-width: 500px;
  }

  .hq_card_image {
    width: 500px;
  }

  .hq_card_image > img {
    width: 500px;
  }

  .hq_card_data {
    flex-direction: row;
    font-size: 14px;
    gap: 10px;
  }

  .hq_card_heading_text {
    font-size: 32px;
  }

  .location_container {
    margin: 0;
    padding: 2rem 4rem;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
  }

  .email_us {
    padding: 2rem 4rem;
  }
  .email_us_content_container {
    flex-direction: row;
    gap: 6rem;
  }

  .email_us_image_container {
    width: 50%;
  }

  .email_us_form_container {
    width: 50%;
  }

  .email_us_two_image {
    flex-direction: row;
    gap: 20px;
  }

  .submit_button {
    justify-content: center;
  }

  .contact_banner_container::after {
    background-color: transparent;
  }
}
