@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin: 0 0 5px 35px;
}

.nav {
  display: flex;
  align-items: center;
  gap: 50px;
  position: relative;
}

.nav-item {
  position: relative;
  margin: 0 15px;
  font-size: 15px;
  color: #565454;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-item:hover {
  color: #ff6b35;
}

.track-package-button {
  background-color: #ff6b35;
  color: white;
  padding: 10px 25px;
  text-decoration: none;
  font-size: 17px;
  transition: background-color 0.3s, color 0.3s;
}

.track-package-button:hover {
  background-color: #e04000;
  color: #ffffff;
}

.nav-item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -17px;
  width: 0;
  height: 5px;
  background-color: #ff6b35;
  transition: width 0.3s;
  z-index: 1;
}

.nav-item.active::after {
  width: 100%;
}

.nav-item:hover::after {
  width: 100%;
}

.nav-item.active {
  color: #000;
}

.nav-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.nav-toggle:hover {
  color: #ff6b35;
}

@media (max-width: 1200px) {
  .nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    flex-direction: column;
    background-color: white;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    z-index: 1000;
    gap: 2rem;

    padding: 2rem 0;
  }

  .nav.open {
    transform: translateX(0);
  }

  .nav-item {
    margin: 20px 0;
  }

  .nav-toggle {
    display: block;
    z-index: 1001;
  }

  .nav-toggle.close {
    position: fixed;
    top: 0px;
    right: 10px;
  }

  .header {
    padding: 0 10px;
    position: relative;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}
