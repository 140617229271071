.about-us-container {
  width: 100%;
  margin: 0 auto;
}

.about-us-banner {
  position: relative;
  text-align: center;
  color: white;
}

.banner-images > img {
  width: 100vw;
}

.about-us-text {
  position: absolute;
  top: 24%;
  left: 15%;
  font-size: 2.5em;
  font-weight: bold;
  padding: 10px 20px;
}

.about-section {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  gap: 2.5rem;
  padding: 2rem;
}

.about-image-container {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  gap: 1.5rem;
}

.about-image-container > img,
.about-image-sub-container > img {
  width: 100%;
}

.about-image-sub-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.about-content-heading {
  text-align: start;
}

.about-content-heading > h2,
.map-section__title > h2 {
  margin: 0;
  padding: 0;

  display: inline-block;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 16px;
}

.about-content-heading > h2::after,
.map-section__title > h2::after {
  content: '';
  display: block;
  width: 50%;
  margin: 8px 0;
  height: 2px;
  background-color: #ff6b34;
}

.about-content .content,
.map-section__content {
  font-size: 16px;
  color: #727272;
}

.map-section {
  width: 100vw;
  padding: 2rem;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  gap: 2.5rem;
}

.map-section > img {
  width: 100%;
}

.map-section__content-container {
  margin: 5rem 2rem;
  width: 80%;
}

.map-section__content {
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .about-us-text {
    position: absolute;
    top: 24%;
    left: 30%;
    font-size: 2.5rem;
    font-weight: bold;
    padding: 10px 20px;
  }

  .about-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
    padding: 2rem;
  }

  .about-content .content,
  .map-section__content,
  .about-content-heading {
    text-align: center;
  }

  .map-section__title {
    text-align: center;
  }

  .map-section__title > h2 {
    text-align: center;
  }

  .about-content-heading > h2::after,
  .map-section__title > h2::after {
    margin: 8px auto;
  }

  .about-content-heading > h2,
  .map-section__title > h2 {
    font-size: 26px;
  }

  .map-section {
    grid-template-columns: 1fr 1fr;
    padding: 1rem 0;
    max-width: 100vw;
  }

  .map-section__content-container {
    width: 90%;
  }

  .map-section__content {
    padding: 0 1rem;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .about-us-banner {
    height: 40vh;
  }

  .banner-images > img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }

  .about-image-container {
    order: 1;
  }

  .about-us-text {
    position: absolute;
    top: 24%;
    left: 28%;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px 20px;
  }

  .about-section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;
    padding: 2rem;
  }

  .about-content .content,
  .map-section__content,
  .about-content-heading {
    text-align: center;
  }

  .about-content-heading > h2,
  .map-section__title > h2 {
    font-size: 24px;
  }

  .about-content-heading > h2::after,
  .map-section__title > h2::after {
    margin: 8px auto;
  }

  .map-section {
    padding: 1rem 0;
    grid-template-columns: 1fr;
  }

  .map-container {
    order: 2;
  }

  .map-india {
    order: 2;
  }

  .map-section__title {
    text-align: center;
  }

  .map-section__content-container {
    order: 1;
    margin: 0 auto;
    width: 90%;
  }
}
