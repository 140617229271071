.slider-container {
  padding-block: 3rem;
  height: auto;
}

.slider-container.slider-bg {
  background-color: #fff;
}

.slider-heading {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-heading > h3 {
  color: #000;
  margin: 0;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  line-height: 30.8px;
  letter-spacing: 2.5px;
  text-align: center;
}

.slider-heading > h3::after {
  content: '';
  display: block;
  width: 50%;
  margin: 8px auto;
  height: 2px;
  background-color: #ff6b34;
}

.slider-buttons {
  position: absolute;
  right: 5%;
}

.slider-button {
  width: 42px;
  height: 42px;
  background-color: #ff6b34;
  outline: none;
  border: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  margin: 8px;
}

.sliding-container {
  width: 90vw;
  margin: 0 auto;
  overflow: hidden;
}

.slider-images {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease;
  padding: 18px 0;
  gap: 20px;
}

.slider-image {
  width: 100%;
  height: 245px;
  object-fit: cover;
}

.slider-container {
  .image-wrapper {
    border: 2px solid #f9f9f9;
    height: 250px;

    img {
      height: 100%;
      object-fit: contain;
      border: none;
      mix-blend-mode: color-burn;
    }
  }

  .slick-track {
    display: flex;

    .slick-slide {
      margin: 0 10px;
    }
  }

  .slick-arrow {
    height: 42px;
    width: 42px;
    background-color: #ff6b34;
    outline: none;
    border: none;
    color: white;
    cursor: pointer;
    margin: 8px;
    top: -5.5rem;
    display: grid !important;
    place-content: center;
  }

  .slick-next {
    right: 10px;

    &.slick-disabled {
      opacity: 0.75;
    }

    &::before {
      content: '>';
      font-size: 24px;
      font-family: Arial, Helvetica, sans-serif;
      opacity: 1;
    }
  }

  .slick-prev {
    right: 62px;
    left: auto;

    &.slick-disabled {
      opacity: 0.75;
    }

    &::before {
      content: '<';
      font-size: 24px;
      font-family: Arial, Helvetica, sans-serif;
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 767px) {
  .slider-container {
    padding-bottom: 6rem;

    .slick-arrow {
      top: auto;
      bottom: -5rem;
    }

    .slick-next {
      right: calc(50% - 52px);
    }

    .slick-prev {
      right: 50%;
    }
  }
}
